// Entry point for the build script in your package.json
import "./controllers"
import * as bootstrap from "bootstrap"
// Color mode toggler has been disabled for now.
// Color mode will be toggled based on system/browser setting if enabled
// import "./color-mode-toggler"
import "./sessions"
import hljs from "./highlight.min"
import Rails from "@rails/ujs"

Rails.start();


window.addEventListener('DOMContentLoaded', function () {
  hljs.highlightAll();

  // Set timeout on auto closing Bootstrap alerts after 3 seconds
  setTimeout(function () {
    if (document.getElementById('flashAlert')) {
      const alert = bootstrap.Alert.getOrCreateInstance('#flashAlert');
      alert.close();
    }
  }, 3000);

  // Javascript for scroll spy on table of contents
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const id = entry.target.getAttribute('id');
      const anchorItem = document.querySelector(`.toc li a[href="#${id}"]`)
      if (anchorItem) {
        const parentListItem = anchorItem.parentElement;
        if (entry.intersectionRatio > 0) {
          parentListItem.classList.add('active');
        } else {
          parentListItem.classList.remove('active');
        }
      }
    });
  });

  // Track all sections that have an `id` applied
  document.querySelectorAll('section[id]').forEach((section) => {
    observer.observe(section);
  });

});
